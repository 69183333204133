import React from "react"
import PropTypes from "prop-types"

import BudgetGraphRateCircle from "./BudgetGraphRateCircle"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const BudgetGraphIndustry = ({ industryRate, industryRateCaption }) => {
  return (
    <RevealWrapper
      className="sticky lg:relative top-8 sm:top-15 lg:top-0"
      innerClassName="h-full"
    >
      <div className="rounded-2xl bg-grey-600 px-4 sm:px-8 lg:px-10 pt-8 pb-10 h-full">
        <BudgetGraphRateCircle
          heading="Industry rate"
          rate={industryRate}
          subheading="per hour*"
        />

        <div className="text-sm lg:px-12 2xl:px-14 font-semibold">
          {industryRateCaption}
        </div>
      </div>
    </RevealWrapper>
  )
}

BudgetGraphIndustry.propTypes = {
  industryRate: PropTypes.string.isRequired,
  industryRateCaption: PropTypes.string.isRequired,
}

export default BudgetGraphIndustry
