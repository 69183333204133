import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BLUE, PINK, YELLOW } from "../../../constants"

import ServiceDetailRow from "./ServiceDetailRow"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const ServiceDetail = ({ data }) => {
  const { rows, backgroundColour, image } = data

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].title,
    }
  }

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return "bg-blue-500"
    }
  }

  return (
    <div
      className={`pt-6 lg:pt-12 relative ${getBackgroundClass()} ${
        imageData.image ? "pb-1/2 lg:pb-1/3" : "pb-6 lg:pb-12"
      }`}
    >
      <RevealWrapper className="z-10 relative">
        <div className="mx-ogs bg-white rounded-2xl px-8 lg:px-0 lg:grid lg:grid-cols-12 lg:gap-x-gs py-14 lg:py-20 space-y-24">
          {rows.map((item, index) => {
            return (
              <ServiceDetailRow key={`${index} ${item.heading}`} item={item} />
            )
          })}
        </div>
      </RevealWrapper>
      {imageData.image && (
        <div className="absolute inset-x-0 bottom-0">
          <GatsbyImage
            className="h-0 pb-9:10 md:pb-4:3 w-full"
            image={imageData.image}
            alt={imageData.alt}
          />
        </div>
      )}
    </div>
  )
}

ServiceDetail.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        unityMark: PropTypes.bool,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            heading: PropTypes.string,
            text: PropTypes.string,
            service: PropTypes.arrayOf(PropTypes.shape()),
          })
        ),
      })
    ),
    backgroundColour: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default ServiceDetail
