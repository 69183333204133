import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BackgroundGradient = ({ gradient }) => {
  return (
    <div
      className={`absolute inset-x-0 z-0 ${
        gradient === "top" ? "top-0" : "bottom-0"
      }`}
    >
      <div className="relative">
        <div
          className={`absolute z-10 inset-x-0 h-12 sm:h-24 md:h-80 from-white ${
            gradient === "top"
              ? "bg-gradient-to-t bottom-0"
              : "bg-gradient-to-b top-0"
          }`}
        ></div>
        <div className="relative z-0 opacity-30">
          <StaticImage
            src="../../images/gradient.jpg"
            alt="Background Gradient"
          />
        </div>
      </div>
    </div>
  )
}

export default BackgroundGradient
