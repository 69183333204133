import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

import Signature from "../../Signature/Signature"

const ImageBanner = ({ data, reduceMobileTopSpacing }) => {
  const {
    smallImage,
    mediumImage,
    largeImage,
    imageMobile,
    imageSize,
    signatureName,
    signatureText,
  } = data

  let imageClasses = ""
  let image = ""

  switch (imageSize) {
    case "small":
      imageClasses = "h-0 pb-4:3 md:h-auto md:pb-0 w-full"
      image = smallImage
      break
    case "medium":
      imageClasses = "h-0 pb-6:7 md:h-auto md:pb-0 w-full"
      image = mediumImage
      break
    case "large":
      imageClasses = "h-0 pb-3:4 md:h-auto md:pb-0 w-full"
      image = largeImage
      break

    default:
      break
  }
  if (!image || image.length === 0) return null

  let imageData = null

  if (imageMobile && imageMobile.length) {
    imageData = withArtDirection(getImage(image[0].localFile), [
      {
        media: "(max-width: 768px)",
        image: getImage(imageMobile[0].localFile),
      },
    ])
  } else {
    imageData = getImage(image[0].localFile)
  }

  const wrapperClasses = reduceMobileTopSpacing
    ? "relative z-0 -mt-24 lg:mt-0"
    : "relative"

  return (
    <div className={wrapperClasses}>
      <GatsbyImage
        className={imageClasses}
        image={imageData}
        alt={image[0].alt || ""}
      />
      <Signature
        className="absolute inset-x-gs text-center mx-auto md:mx-0 md:text-left top-12 md:left-15 text-white z-10 max-w-sm"
        name={signatureName}
        text={signatureText}
      />
    </div>
  )
}

ImageBanner.propTypes = {
  smallImage: PropTypes.shape(),
  mediumImage: PropTypes.shape(),
  largeImage: PropTypes.shape(),
  imageMobile: PropTypes.shape(),
  imageSize: PropTypes.string,
  signatureName: PropTypes.string,
  signatureText: PropTypes.string,
}

export default ImageBanner
