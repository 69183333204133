import React from "react"
import PropTypes from "prop-types"

const FeaturesItem = ({
  heading,
  text,
  index,
  style,
  circleClass,
  featureWidthClass,
}) => {
  let contentWrapperClasses = ""
  let contentClasses = ""

  if (style === "default") {
    contentWrapperClasses =
      "bg-white rounded-2xl flex-shrink-0 pb-1:1 h-0 relative w-full"
    contentClasses =
      "absolute inset-gs md:inset-6 2xl:inset-x-12 flex flex-col items-center justify-center"
  } else {
    contentWrapperClasses =
      "bg-pink-300 rounded-lg px-gs pb-gs pt-16 h-full w-full"
    contentClasses = "h-full"
  }

  const featureItemClass = `${featureWidthClass} flex flex-col items-center h-full`
  return (
    <div className={featureItemClass}>
      <div className={circleClass}>{index}</div>
      <div className={contentWrapperClasses}>
        <div className={contentClasses}>
          {heading && (
            <h5 className="text-2xl font-semibold mb-5">{heading}</h5>
          )}
          {text && (
            <p className="text-sm xs:text-base whitespace-pre-wrap">{text}</p>
          )}
        </div>
      </div>
    </div>
  )
}

FeaturesItem.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
  circleClass: PropTypes.string,
  style: PropTypes.string,
}

export default FeaturesItem
