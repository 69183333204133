import React from "react"
import PropTypes from "prop-types"

import ContactUsOption from "./ContactUsOption"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const ContactUs = ({ data }) => {
  const { heading, text, includeDivider, options } = data

  let borderClasses = ""
  if (includeDivider) {
    borderClasses = "border-b-2 border-white"
  }

  return (
    <div
      className={`mx-ogs pt-14 pb-10 lg:grid lg:grid-cols-12 lg:gap-x-gs col-start-2 col-span-10`}
    >
      <div className={`${borderClasses} col-start-2 col-span-10 pb-40`}>
        <div className="text-center mx-auto max-w-4xl mb-16 pt-4 lg:pt-18 lg:mb-24">
          <h2 className="font-semibold text-3xl lg:text-4xl mb-8">{heading}</h2>
          <h6 className="text-xl font-medium">{text}</h6>
        </div>

        <div className="flex flex-wrap gap-10 lg:gap-x-4 justify-center">
          {options.map((option, index) => (
            <RevealWrapper
              className="lg:flex-1"
              key={option.id}
              delay={index * 250}
            >
              <ContactUsOption
                icon={option.icon}
                url={option.linkTo.url}
                target={option.linkTo.target}
                text={option.linkTo.customText}
              />
            </RevealWrapper>
          ))}
        </div>
      </div>
    </div>
  )
}

ContactUs.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
    includeDivider: PropTypes.bool,
    options: PropTypes.array.isRequired,
  }),
}

export default ContactUs
