import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import "swiper/swiper-bundle.css"

import {
  SCREEN_WIDTH_LG,
  SCREEN_WIDTH_XL,
  SCREEN_WIDTH_3XL,
} from "../../../constants"
import "./ReviewsCarousel.css"

import ReviewsCard from "./ReviewsCard"
SwiperCore.use([Navigation, Pagination])

const ReviewsCarousel = ({ data }) => {
  const reviewsCarouselRef = useRef()
  const [inView, setInView] = useState(false)
  const windowW = typeof window !== "undefined" ? window.innerWidth : ""
  const [windowWidth, setWindowWidth] = useState(windowW)

  let outsideGutterSize

  if (windowWidth >= SCREEN_WIDTH_3XL) {
    outsideGutterSize = 240
  } else if (windowWidth >= SCREEN_WIDTH_XL) {
    outsideGutterSize = 48
  } else if (windowWidth >= SCREEN_WIDTH_LG) {
    outsideGutterSize = 40
  } else {
    outsideGutterSize = 24
  }

  const columnSize = (windowWidth - outsideGutterSize * 2) / 12
  const offsetBefore =
    outsideGutterSize + (windowWidth >= SCREEN_WIDTH_LG ? columnSize : "")

  let cardSize = windowWidth >= SCREEN_WIDTH_LG ? 416 : 288
  const offsetAfter = windowWidth - offsetBefore - cardSize - outsideGutterSize

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)

    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setInView(true)
        }
      })
    })

    observer.observe(reviewsCarouselRef.current)
    return () => {
      window.removeEventListener("resize", handleResize)
      observer.disconnect()
    }
  }, [])

  const { heading, reviews } = data
  return (
    <div className="py-20 ReviewsCarousel" ref={reviewsCarouselRef}>
      {heading && (
        <div className="px-ogs grid grid-cols-4 lg:grid-cols-12 gap-gs">
          <div className="col-span-4 lg:col-start-2 md:col-span-8">
            <h3 className="font-semibold text-3xl mb-8">{heading}</h3>
          </div>
        </div>
      )}
      {reviews.length > 0 && (
        <div className="overflow-hidden">
          <Swiper
            slidesPerView="auto"
            freeMode={true}
            grabCursor
            slideToClickedSlide={true}
            pagination={{ clickable: true }}
            slidesOffsetAfter={offsetAfter}
            slidesOffsetBefore={offsetBefore}
          >
            {reviews.map((item, index) => {
              let delay = index * 120 + 500
              return (
                <SwiperSlide
                  key={item.id + index}
                  style={{ transitionDelay: delay + "ms" }}
                  className={`${
                    inView ? "" : "translate-x-screen"
                  } translate-x-0 transform`}
                >
                  <ReviewsCard item={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
    </div>
  )
}

ReviewsCarousel.propTypes = {
  heading: PropTypes.string,
  item: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
  }),
}

export default ReviewsCarousel
