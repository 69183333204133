import React from "react"

const PricingEllipse = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
      <circle cx="5.5" cy="5.5" r="5.5" />
    </svg>
  )
}

export default PricingEllipse
