import React from "react"

import IconChevronLeft from "../../SVG/IconChevronLeft"
import IconChevronRight from "../../SVG/IconChevronRight"

const ServicesCarouselButton = ({ direction, handleClick }) => {
  return (
    <button
      onClick={() => {
        handleClick()
      }}
      className={`${direction} border-2 rounded-full w-10 h-10 flex items-center justify-center bg-white border-blue-400 text-blue-500 hover:bg-blue-400 transition-colors`}
    >
      <span
        className={`${
          direction === "previous" ? "mr-0.5" : "ml-0.5"
        } mt-0.5 w-3.5 h-5`}
      >
        <span className="sr-only">{direction} slide</span>
        {direction === "previous" ? <IconChevronLeft /> : <IconChevronRight />}
      </span>
    </button>
  )
}

const ServicesCarouselControls = ({ next, previous }) => {
  return (
    <div className="lg:col-start-2 lg:col-span-4 flex space-x-3">
      <ServicesCarouselButton direction="previous" handleClick={previous} />
      <ServicesCarouselButton direction="next" handleClick={next} />
    </div>
  )
}

export default ServicesCarouselControls
