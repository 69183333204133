import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

const CurrentStatus = ({ data }) => {
  const {
    statusHeading,
    statusValue,
    heading,
    text,
    imagePrimary,
    imageSecondary,
  } = data

  let image = {}

  if (imageSecondary.length && imagePrimary.length) {
    image = withArtDirection(getImage(imagePrimary[0].localFile), [
      {
        media: "(max-width: 1024px)",
        image: getImage(imageSecondary[0].localFile),
      },
    ])
  } else if (imagePrimary.length) {
    image = getImage(imagePrimary[0].localFile)
  }

  return (
    <div className="mx-ogs pt-18 lg:pt-30 lg:grid lg:grid-cols-12 lg:gap-x-gs font-semibold">
      {heading && (
        <div className="lg:col-span-8 xl:col-start-2 mb-18 lg:mb-15">
          <h2 className="text-3xl xl:text-4xl">{heading}</h2>
        </div>
      )}
      <div className="lg:col-span-12 xl:col-start-2 xl:col-span-10 lg:flex lg:items-start space-y-15 lg:space-y-0 lg:gap-x-8 3xl:gap-x-15">
        {text && <p className="text-lg 2xl:text-xl">{text}</p>}

        {(statusHeading || statusValue) && (
          <div className="text-sm md:text-md 3xl:text-xl inline-flex items-center lg:flex-shrink-0 rounded-md lg:rounded-lg bg-blue-500 bg-opacity-10 p-1 lg:p-2">
            {statusHeading && (
              <span className="px-4 lg:pl-6 lg:pr-8 flex-shrink-0">
                {statusHeading}
              </span>
            )}
            {statusValue && (
              <span className="bg-white rounded h-full w-full flex items-center px-4 py-3 lg:py-5 lg:px-8">
                {statusValue}
              </span>
            )}
          </div>
        )}
      </div>
      {image.images && (
        <div className="lg:col-span-12 rounded-t-4xl overflow-hidden mt-24 lg:mt-32">
          <GatsbyImage
            className="pb-1:1 h-0 lg:h-80 3xl:h-96 lg:pb-0 w-full"
            image={image}
            alt={imagePrimary[0]?.title}
          />
        </div>
      )}
    </div>
  )
}

CurrentStatus.propTypes = {
  data: PropTypes.shape(),
}

export default CurrentStatus
