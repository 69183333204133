import React from "react"
import PropTypes from "prop-types"
import HeroCard from "./HeroCard"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const HeroCards = ({ data }) => {
  if (data.cards.length === 0) return null
  const { cards, columns, centreText } = data

  const wrapperPositionClasses =
    columns === "two"
      ? "lg:col-span-10 grid lg:grid-cols-2 gap-y-6 lg:gap-gs lg:col-start-2"
      : "lg:col-span-10 xl:col-start-2 xl:col-span-10 grid lg:grid-cols-3 gap-y-6 lg:gap-gs "
  const textClasses = centreText ? "text-center" : ""

  const wrapperClasses = `${wrapperPositionClasses} ${textClasses}`
  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-gs px-ogs my-6">
      <div className={wrapperClasses}>
        {cards.map((item, index) => {
          return (
            <RevealWrapper
              className="h-full"
              innerClassName="h-full"
              key={item.heading + index}
              delay={index * 250}
            >
              <HeroCard
                data={item}
                columns={columns}
                borderRadius={
                  centreText ? "rounded-2xl" : "rounded-2xl lg:rounded-4xl"
                }
              />
            </RevealWrapper>
          )
        })}
      </div>
    </div>
  )
}

HeroCards.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.array.isRequired,
    columns: PropTypes.string,
    centreText: PropTypes.bool,
  }),
}

export default HeroCards
