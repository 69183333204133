import React, { useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CSSTransition } from "react-transition-group"

import IconPlay from "../../SVG/IconPlay"

const VideoPlayer = ({ videoId, posterImage }) => {
  const [playVideo, setPlayVideo] = useState(false)

  const imageData = {
    image: getImage(posterImage[0]?.localFile),
    alt: posterImage[0]?.alt || "",
  }

  return (
    <div className="VideoPlayer">
      <div className="VideoPlayer__inner">
        <CSSTransition
          in={playVideo}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <iframe
            className="VideoPlayer__embed"
            title="Video player"
            src={`https://player.vimeo.com/video/${videoId}?autoplay=true`}
            frameborder="0"
            allow="autoplay; fullscreen;"
            allowfullscreen
          ></iframe>
        </CSSTransition>
        <CSSTransition
          in={!playVideo}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="VideoPlayer__cover">
            {imageData?.image && (
              <GatsbyImage
                className="h-full w-full"
                image={imageData.image}
                alt={imageData.alt}
              />
            )}

            <div className="VideoPlayer__button-wrapper">
              <button
                onClick={() => setPlayVideo(true)}
                className="VideoPlayer__button"
              >
                <span className="sr-only">Play video</span>
                <IconPlay />
              </button>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}

VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  posterImage: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      localFile: PropTypes.shape(),
    })
  ).isRequired,
}

export default VideoPlayer
