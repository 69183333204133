import React from "react"
import PropTypes from "prop-types"

import BudgetGraphRateCircle from "./BudgetGraphRateCircle"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const BudgetGraphFrankie = ({
  frankieRateHeading,
  frankieRate,
  frankieRateCaption,
  frankieRateSavings,
}) => {
  return (
    <RevealWrapper className="sticky lg:relative top-8 sm:top-15 lg:top-0">
      <div className="rounded-2xl overflow-hidden w-full">
        <div className="px-4 sm:px-8 lg:px-10 pb-10 pt-8 bg-yellow-500">
          <BudgetGraphRateCircle
            heading={frankieRateHeading}
            subheading="per hour^"
            rate={frankieRate}
            background="bg-blue-500 text-white"
            rateSavings={frankieRateSavings}
          />

          <div className="text-black text-sm px-4 lg:px-12 2xl:px-30 font-semibold">
            {frankieRateCaption && frankieRateCaption}
          </div>
        </div>
      </div>
    </RevealWrapper>
  )
}

BudgetGraphFrankie.propTypes = {
  frankieRate: PropTypes.string.isRequired,
  frankieRateHeading: PropTypes.string.isRequired,
  frankieRateCaption: PropTypes.string.isRequired,
  frankieRateSavings: PropTypes.string.isRequired,
}

export default BudgetGraphFrankie
