import React from "react"

import "./CareProfessionalsCard.css"

import IconPinSmall from "../../SVG/IconPinSmall"
import IconStar from "../../SVG/IconStar"
import IconDollar from "../../SVG/IconDollar"

const CareProfessionalsCard = ({ careProf }) => {
  const NUM_SERVICES = 4
  const NUM_HOBBIES = 3

  const shuffle = array => {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  const langsBesidesEng = langs => {
    return langs.filter(
      language => language && language.toLowerCase() !== "english"
    )
  }

  return (
    <div className="rounded-xl w-72 md:w-96 overflow-hidden">
      <div>
        {careProf.avatar && (
          <img
            className="h-80 pb-0 w-full object-cover"
            src={careProf.avatar}
            alt={careProf.name}
          />
        )}
      </div>

      <div className="bg-white p-4 text-left space-y-2 ">
        <div className="flex justify-between">
          <h3 className="text-xl text-blue-500 text-left">{careProf.name}</h3>
          {careProf.hourly_rate && (
            <div className="inline-flex items-center">
              <div className="w-3.5 h-3.5 flex-shrink-0">
                <IconDollar />
              </div>
              <div className="leading-none text-grey-500">
                {careProf.hourly_rate}/hr+
              </div>
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="gap-2">
            <div className="text-grey-900 font-normal">
              <div className="flex justify-start items-center gap-3">
                {careProf.address?.state && (
                  <div className="flex">
                    <div className="w-3.5 h-3.5 mr-2">
                      <IconPinSmall />
                    </div>
                    <div className="leading-none">{careProf.address.state}</div>
                  </div>
                )}

                {careProf.rating === 0 ? "" : "|"}
                <div className="flex items-center self-center">
                  <div className="w-3.5 h-3.5 mr-2">
                    {careProf.rating === "0" ? "" : <IconStar />}
                  </div>
                  {careProf.rating === "0" ? "" : Math.floor(careProf.rating)}
                </div>
              </div>
              <div>
                <h1 className="text-sm text-grey-500 mt-5 font-bold">
                  Services
                </h1>
              </div>
              <div>
                {careProf.services?.length && (
                  <div className="font-medium text-sm mt-1 mb-4">
                    {careProf.services
                      .slice(0, NUM_SERVICES)
                      .map((service, index) => (
                        <div className="flex items-center" key={index}>
                          <span>{service}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {careProf.hobbies && (
            <div className="CardSection--large flex flex-col mb-2">
              <h1 className="text-sm text-grey-500 font-bold mt-1">
                Interests
              </h1>
              {careProf.hobbies.length > 0 ? (
                <div className=" text-charcoal-500 flex gap-2 items-center w-full mt-3">
                  {careProf.hobbies
                    .slice(0, NUM_HOBBIES)
                    .map((hobby, index) => (
                      <div key={index}>
                        <span className="line-clamp-1 bg-blue-100 rounded-full py-1 px-1 md:px-3 w-full">
                          {hobby}
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <div className=" text-charcoal-500 flex gap-2 items-center w-full mt-3">
                  No interests listed.
                </div>
              )}
            </div>
          )}

          {careProf.languages && (
            <div className="CardSection--small  flex flex-col">
              <div>
                <h1 className="text-sm text-grey-500 mt-1 font-bold">
                  Languages
                </h1>
              </div>
              <div className="flex space-x-3 mt-3">
                {langsBesidesEng(careProf.languages).length > 0 ? (
                  langsBesidesEng(careProf.languages).map(lang => {
                    if (lang === "English") {
                      return null
                    }
                    return (
                      <div className="flex items-center space-x-1">
                        <div>{lang}</div>
                      </div>
                    )
                  })
                ) : (
                  <div className=" text-charcoal-500 flex gap-2 items-center w-full">
                    No languages listed.
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CareProfessionalsCard
