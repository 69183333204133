import React from "react"
import PropTypes from "prop-types"

import { BLUE, PINK, YELLOW } from "../../../constants"

import LinkCard from "./LinkCard"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const LinkCards = ({ data }) => {
  const { primaryCard, secondaryCard, backgroundColour } = data

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return "bg-blue-500"
    }
  }

  return (
    <div className="mx-ogs py-8 lg:py-32 lg:grid lg:grid-cols-12 lg:gap-x-gs">
      <div className="lg:col-start-2 lg:col-span-10 md:grid md:grid-cols-2 divide-y-3 md:divide-y-0 md:divide-x-3 divide-white">
        {primaryCard && (
          <RevealWrapper>
            <LinkCard
              className={`${getBackgroundClass()} mb-8 md:mb-0 md:mr-8`}
              card={primaryCard}
            />
          </RevealWrapper>
        )}
        {secondaryCard && (
          <RevealWrapper delay={250}>
            <LinkCard
              className={`${getBackgroundClass()} mt-8 md:mt-0 md:ml-8`}
              card={secondaryCard}
            />
          </RevealWrapper>
        )}
      </div>
    </div>
  )
}

LinkCards.propTypes = {
  data: PropTypes.shape({
    primaryCard: PropTypes.string,
    secondaryCard: PropTypes.string,
    backgroundColour: PropTypes.string,
  }),
}

export default LinkCards
