import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import PricingCards from "./PricingCards"

const Pricing = ({ data }) => {
  const {
    heading,
    lead,
    levelCard,
    packageValueFinePrint,
    image,
    backgroundGradient,
  } = data

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].title,
    }
  }

  const serviceCategories = levelCard
    .reduce((prev, curr) => {
      return [...prev, ...curr.services]
    }, [])
    .map(item => item.serviceCategory[0])
    .map(item => [item["title"], item])

  const uniqueServiceCategories = [...new Map(serviceCategories).values()]

  return (
    <div className="bg-blue-500 pt-16 pb-8">
      <RevealWrapper className="relative z-10">
        <div
          className={`mx-ogs rounded-2xl bg-white pt-18 pb-8 lg:grid lg:grid-cols-12 lg:py-12 lg:gap-x-gs${
            backgroundGradient === "none" ? "" : " shadow-lg"
          }`}
        >
          {heading && (
            <div className="px-6 lg:col-span-6 lg:col-start-4 lg:px-0">
              <h4 className="whitespace-pre-wrap pb-8 text-center text-3xl font-semibold text-charcoal-500">
                {heading}
              </h4>
            </div>
          )}
          {lead && (
            <div className="px-6 text-center lg:col-span-8 lg:col-start-3 lg:px-0">
              <div className="whitespace-pre-wrap text-md font-medium leading-6 text-charcoal-500">
                {lead}
              </div>
            </div>
          )}
          <div className="pt-8 md:pt-12 lg:col-span-10 lg:col-start-2 lg:pt-18">
            {levelCard.length > 0 && <PricingCards cards={levelCard} />}

            <div className="grid gap-y-8 gap-x-gs px-6 text-sm leading-loose items-start lg:grid-cols-3 lg:px-0">
              <div className="flex flex-wrap gap-y-2 gap-x-8">
                {uniqueServiceCategories?.length > 0 &&
                  uniqueServiceCategories.map((item, index) => {
                    return (
                      <div
                        key={`${item.title} ${index}`}
                        className="flex items-center space-x-1 md:space-x-3"
                      >
                        <div
                          className="h-3 w-3 rounded-full flex-shrink-0"
                          style={{ background: item.colour }}
                        ></div>
                        <div>{item.title}</div>
                      </div>
                    )
                  })}
              </div>
              <div className="text-charcoal-400 lg:col-span-2 lg:col-start-1 lg:row-start-1 text-xs">
                {packageValueFinePrint && <div>{packageValueFinePrint}</div>}
                {levelCard.map((item, index) => {
                  return (
                    <div key={`${item.finePrint} ${index}`}>
                      {item.finePrint}
                    </div>
                  )
                })}
                <div>Hourly rates exclude GST (where applicable).</div>
              </div>
            </div>
          </div>
        </div>
      </RevealWrapper>
      {imageData.image && (
        <div className="absolute inset-x-0 bottom-0">
          <GatsbyImage
            className="pb-9:10 md:pb-4:3 h-0 w-full"
            image={imageData.image}
            alt={imageData.alt}
          />
        </div>
      )}
    </div>
  )
}

Pricing.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    lead: PropTypes.string,
    levelCard: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        packageValue: PropTypes.string,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            serviceCategory: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                colour: PropTypes.string,
              })
            ),
          })
        ),
        hoursWeek: PropTypes.string,
      })
    ),
    packageValueFinePrint: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.object),
    backgroundGradient: PropTypes.string,
  }),
}

export default Pricing
