import React from "react"
import PropTypes from "prop-types"

import Button from "../../Button/Button"
import Signature from "../../Signature/Signature"
import TextAndImagesImageSingle from "./TextAndImagesImageSingle"
import TextAndImagesImageDouble from "./TextAndImagesImageDouble"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const TextAndImages = ({
  data,
  reduceTopSpacing = "",
  reduceBottomSpacing = "",
}) => {
  const {
    body,
    button,
    images,
    signatureName,
    signatureText,
    imageAlignment,
    imageOffset,
    imageWidth,
    centreText,
  } = data

  let imagePlacementClasses = ""

  if (imageAlignment === "left") {
    imagePlacementClasses =
      images.length === 1 && imageOffset ? "lg:col-start-1" : "lg:col-start-2"
  } else {
    if (images.length === 1) {
      imagePlacementClasses =
        imageWidth === "wider" ? "lg:col-start-7" : "lg:col-start-8"
    } else {
      imagePlacementClasses = "lg:col-start-7"
    }
  }

  let imageWidthClasses = ""

  if (images.length === 1) {
    if (imageOffset) {
      if (imageWidth === "wider") {
        imageWidthClasses =
          imageAlignment === "left"
            ? "col-span-4 lg:col-span-6 -ml-ogs"
            : "col-span-4 lg:col-span-6 -mr-ogs"
      } else {
        imageWidthClasses =
          imageAlignment === "left"
            ? "col-span-4 lg:col-span-5 -ml-ogs"
            : "col-span-4 lg:col-span-5 -mr-ogs"
      }
    } else {
      imageWidthClasses = "col-span-4"
    }
  } else {
    imageWidthClasses = "col-span-4 lg:col-span-5"
  }

  const imageWrapperClasses = `lg:row-start-1 ${imagePlacementClasses} ${imageWidthClasses}`

  let contentPlacementClasses = ""
  if (imageAlignment === "left") {
    contentPlacementClasses =
      imageWidth === "wider" || images.length === 2
        ? "col-span-4 lg:row-start-1 lg:col-span-4 lg:col-start-8"
        : "col-span-4 lg:row-start-1 lg:col-span-5 lg:col-start-7"
  } else {
    contentPlacementClasses =
      imageWidth === "wider"
        ? "col-span-4 lg:row-start-1 lg:col-start-2 lg:col-span-4 lg:mr-ogs 3xl:mr-24"
        : "col-span-4 lg:row-start-1 lg:col-start-2 lg:col-span-5"
  }

  const contentClasses = `${contentPlacementClasses} ${
    centreText ? "text-center lg:text-left" : ""
  } max-w-xl mx-auto lg:mx-0`

  return (
    <div
      className={`grid grid-cols-4 lg:grid-cols-12 gap-y-12 md:gap-y-24 gap-x-gs px-ogs lg:items-center overflow-hidden ${
        !reduceTopSpacing && "pt-15 md:pt-24"
      } ${!reduceBottomSpacing && "xl:pb-32"} pb-20 md:pb-24`}
    >
      <div className={contentClasses}>
        {body !== null && (
          <div className="rte" dangerouslySetInnerHTML={{ __html: body }} />
        )}

        {button.url !== null && (
          <div className="mt-8 inline-block">
            <Button button={button} />
          </div>
        )}
      </div>
      <RevealWrapper className={imageWrapperClasses}>
        {images.length === 1 ? (
          <>
            <TextAndImagesImageSingle
              images={images}
              imageOffset={imageOffset}
              imageAlignment={imageAlignment}
            />
            <Signature
              text={signatureText}
              name={signatureName}
              className="text-center max-w-xs mx-auto mt-8"
            />
          </>
        ) : (
          <>
            <TextAndImagesImageDouble
              images={images}
              imageOffset={imageOffset}
              signatureText={signatureText}
              signatureName={signatureName}
            />
            {!imageOffset && (
              <Signature
                text={signatureText}
                name={signatureName}
                className="text-center max-w-xs mx-auto mt-8"
              />
            )}
          </>
        )}
      </RevealWrapper>
    </div>
  )
}

TextAndImages.propTypes = {
  body: PropTypes.string,
  button: PropTypes.shape(),
  images: PropTypes.shape(),
  signatureName: PropTypes.string,
  signatureText: PropTypes.string,
  imageAlignment: PropTypes.string,
  imageOffset: PropTypes.bool,
  imageWidth: PropTypes.string,
  centreText: PropTypes.bool,
}

export default TextAndImages
