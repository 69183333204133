import React from "react"
import PropTypes from "prop-types"
import PricingEllipse from "../../SVG/PricingCardEllipse"

const PricingCard = ({ data }) => {
  const { heading, packageValue, services, hoursWeek } = data
  return (
    <div className="flex h-full flex-col justify-start overflow-hidden rounded-lg bg-blue-400 pt-5 lg:w-auto">
      {heading && (
        <h4 className="px-2.5 pb-4 text-center font-semibold text-blue-500">
          {heading}
        </h4>
      )}
      {services?.length > 0 && (
        <div className="mb-auto pb-8 space-y-2 px-1.5 lg:px-7">
          {services.map((item, index) => {
            return (
              <div
                style={{ color: item.serviceCategory[0].colour }}
                className="flex items-center justify-between rounded-md border bg-white px-2 py-3 text-xs  lg:text-sm xl:text-base"
                key={`${item.title} ${index}`}
              >
                <div className="text-charcoal-500 text-md">{item.title}</div>
                <div
                  className="rounded-full bg-current"
                  style={{ background: item.colour }}
                >
                  <PricingEllipse />
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="flex items-center justify-center mt-3 bg-blue-500 p-4 text-center text-white lg:mt-7">
        <div className="flex w-full flex-col xl:flex-row justify-between gap-3 lg:space-y-1">
          {packageValue && (
            <div className="text-xl md:text-2xl font-bold self-center flex-shrink-0">
              {packageValue}
            </div>
          )}
          {hoursWeek && <div className="text-base md:text-md">{hoursWeek}</div>}
        </div>
      </div>
    </div>
  )
}

PricingCard.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    packageValue: PropTypes.string,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        serviceCategory: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            colour: PropTypes.string,
          })
        ),
      })
    ),
    hoursWeek: PropTypes.string,
  }),
}

export default PricingCard
