import React from "react"
import PropTypes from "prop-types"

import TransitionLinkFade from "../../TransitionLink/TransitionLinkFade"
import Logomark from "../../SVG/Logomark"

const ServiceDetailRow = ({ item }) => {
  const { heading, unityMark, columns } = item
  const colLength = columns.length
  return (
    <div className="lg:col-span-10 lg:col-start-2">
      {heading && (
        <div
          className={`${
            unityMark
              ? "lg:flex lg:items-center space-y-2.5 lg:space-y-0 lg:space-x-5 pb-5 lg:pb-15"
              : "pb-15 lg:pb-8"
          } text-center lg:text-left`}
        >
          {unityMark && (
            <div className="w-6 h-3.5 mx-auto lg:mx-0 text-blue-500">
              <Logomark />
            </div>
          )}
          <h3 className="text-3xl lg:text-base font-semibold lg:tracking-widest whitespace-pre-wrap">
            {heading}
          </h3>
        </div>
      )}

      {colLength > 0 && (
        <div
          className={`max-w-xs mx-auto lg:max-w-none lg:mx-0 grid lg:grid-cols-3 divide-y-3 divide-blue-200 lg:divide-y-0 gap-y-10 lg:gap-y-0 lg:border-l-2 border-blue-200 ${
            unityMark
              ? ""
              : "border-t-3 border-b-3 lg:border-t-0 lg:border-b-0 pb-10 lg:pb-0"
          }`}
        >
          {columns.map((item, index) => {
            const { heading, text, service } = item

            const realIndex = index + 1

            let borderTClass = ""
            // adds top border to columns in the first row only
            if (realIndex <= 3) {
              borderTClass = "lg:border-t-2"
            } else {
              borderTClass = "lg:border-t-0"
            }

            const title = service[0]?.title
            const slug = service[0]?.slug

            return (
              <div
                key={`${index} ${heading || title}`}
                className="lg:border-r-2 lg:border-blue-200"
              >
                <div className="lg:mx-2.5 lg:px-2.5 xl:mx-5 xl:px-5 lg:h-full">
                  <div
                    className={`pt-10 lg:pb-10 space-y-5 lg:h-full lg:border-b-2 lg:border-blue-200 ${borderTClass}`}
                  >
                    {(heading || title) &&
                      (slug ? (
                        <div className="text-center lg:text-left">
                          <TransitionLinkFade
                            className="transition-opacity duration-base hover:opacity-75 inline-block"
                            to={`/services/${slug}`}
                          >
                            <h4 className="font-semibold text-blue-500 text-xl">
                              {heading || title}
                            </h4>
                          </TransitionLinkFade>
                        </div>
                      ) : (
                        <h4 className="text-center lg:text-left font-semibold text-blue-500 text-xl">
                          {heading || title}
                        </h4>
                      ))}

                    {text && (
                      <div
                        className="rte rte--service-detail"
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

ServiceDetailRow.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string,
    unityMark: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        service: PropTypes.arrayOf(PropTypes.shape()),
      })
    ),
  }),
}

export default ServiceDetailRow
