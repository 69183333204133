import React from "react"
import PropTypes from "prop-types"

const ContactUsOption = ({ icon, url, target, text }) => {
  return (
    <div className="relative pt-10">
      <a
        className="flex items-center justify-center h-25 p-4 bg-blue-500 border-2 border-blue-500 rounded-xl text-2xl text-white transition hover:opacity-100 lg:hover:bg-white lg:hover:text-blue-500"
        href={url}
        target={target}
      >
        <div className="w-14 h-14 absolute top-0 left-1/2 transform -translate-x-1/2">
          <img
            className="h-full max-w-none absolute top-0 right-0"
            src={icon[0].url}
            alt={icon[0].title}
          />
        </div>
        <span className="border-b">{text}</span>
      </a>
    </div>
  )
}

ContactUsOption.propTypes = {
  icon: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default ContactUsOption
