import React from "react"

const IconPlay = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 57 66">
      <path d="M1.67641 2.11497L55.5965 33.2457L1.67641 64.3765L1.67641 2.11497Z" />
    </svg>
  )
}

export default IconPlay
