import React from "react"
import PropTypes from "prop-types"

import "./ContentColumns.css"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const ContentColumns = ({ data, reduceTopSpacing }) => {
  const { textColumns } = data

  let gridClasses = ""

  if (textColumns.length === 3) {
    gridClasses =
      "lg:col-span-10 lg:col-start-2 grid gap-y-12 lg:gap-y-0 lg:grid-cols-3 gap-x-12 2xl:gap-x-20"
  } else if (textColumns.length === 2) {
    gridClasses =
      "lg:col-span-10 lg:col-start-2 grid gap-y-12 lg:gap-y-0 lg:grid-cols-2 gap-x-20 2xl:gap-x-32"
  } else {
    gridClasses = "lg:col-span-6 lg:col-start-4"
  }

  return (
    <div
      className={`ContentColumns px-ogs grid lg:grid-cols-12 lg:gap-gs mb-24 lg:mb-32 ${
        reduceTopSpacing ? "" : "mt-24 lg:mt-32"
      }`}
    >
      <div className={gridClasses}>
        {textColumns.map((item, index) => {
          return (
            <RevealWrapper key={`${item.body} ${index}`} delay={index * 250}>
              <div
                className="rte"
                dangerouslySetInnerHTML={{ __html: item.body }}
              ></div>
            </RevealWrapper>
          )
        })}
      </div>
    </div>
  )
}

ContentColumns.propTypes = {
  data: PropTypes.shape({
    textColumns: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      })
    ),
  }),
}

export default ContentColumns
