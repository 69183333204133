import React from "react"

const IconChevronRight = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 6 10">
      <path d="M.706994 8.70709l-.70710743-.7071L3.64633 4.35354-.00011279.707091.706994-.00001564 5.06055 4.35354.706994 8.70709z" />
    </svg>
  )
}

export default IconChevronRight
