import React from "react"
import PropTypes from "prop-types"

import { BLUE, NONE, PINK, WHITE, YELLOW } from "../../../constants"

import Button from "../../Button/Button"
import Logomark from "../../SVG/Logomark"

const CallToAction = ({ data }) => {
  const { text, textColour, backgroundColour, unityMark, button } = data
  const buttonStyle = backgroundColour === BLUE ? "secondary" : "primary"

  const unityMarkStyle =
    backgroundColour === WHITE || backgroundColour === NONE
      ? "text-blue-500 opacity-10"
      : "text-white opacity-20"

  const textClass =
    textColour === "charcoal" ? "text-charcoal-500" : "text-white"

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case NONE:
        return ""
      case WHITE:
        return "bg-white"
      case PINK:
        return "bg-pink-500"
      case BLUE:
        return "bg-blue-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return ""
    }
  }

  return (
    <div className={`${getBackgroundClass()} relative overflow-hidden`}>
      <div
        className={`text-center px-ogs ${
          unityMark ? "py-24 lg:py-30" : "py-18 lg:py-24"
        } relative z-10`}
      >
        {text && (
          <div className="xs:w-3/4 2xl:w-1/2 mx-auto">
            <h2 className={`${textClass} font-semibold text-3xl lg:text-4xl`}>
              {text}
            </h2>
          </div>
        )}
        {button.url && (
          <div className="inline-block mt-6">
            <Button button={button} buttonStyle={buttonStyle} />
          </div>
        )}
      </div>
      {unityMark && (
        <div
          className={`${unityMarkStyle} absolute inset-y-0 left-0 sm:left-auto sm:right-0 transform -rotate-12 -ml-4 mt-12 sm:mt-0 sm:ml-0 sm:-mr-12 -mb-4`}
        >
          <Logomark />
        </div>
      )}
    </div>
  )
}

CallToAction.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    textColour: PropTypes.string,
    backgroundColour: PropTypes.string,
    unityMark: PropTypes.bool,
    button: PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      target: PropTypes.string,
      url: PropTypes.string,
      element: PropTypes.shape({
        uri: PropTypes.string,
      }),
    }),
  }),
}

export default CallToAction
