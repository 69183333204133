import React from "react"
import PropTypes from "prop-types"
import IconChevronRight from "../../SVG/IconChevronRight"

const AccordionItemMobile = ({ item, activeIndex, handleClick, index }) => {
  const { icon, heading, text } = item

  return (
    <div
      className={`AccordionItem ${
        activeIndex === index ? "AccordionItem--active" : ""
      }`}
    >
      <button
        onClick={() => {
          handleClick(index)
        }}
        className="AccordionItem__button"
      >
        <div className="flex items-center gap-3">
          <div className="h-8 w-8">
            <img src={icon[0].url} alt={icon[0].title} />
          </div>
          {heading}
        </div>

        <span
          className={`AccordionItem__button-icon ${
            activeIndex === index ? "AccordionItem__button-icon--active" : ""
          }`}
        >
          <IconChevronRight />
        </span>

        <span className={`AccordionItem__button-highlight`}></span>
      </button>
      <div className="overflow-hidden">
        <div className={`${activeIndex === index ? "h-auto" : "h-0"}`}>
          <div
            className={`AccordionItem__content ${
              activeIndex === index ? "" : "hidden"
            }`}
          >
            {text && <p>{text}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

AccordionItemMobile.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.arrayOf(PropTypes.shape()),
    image: PropTypes.arrayOf(PropTypes.shape()),
    heading: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default AccordionItemMobile
