import React from "react"
import PropTypes from "prop-types"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import HubspotForm from "react-hubspot-form"

const HubspotEmbed = ({ data }) => {
  const { formId } = data

  return (
    <RevealWrapper className="relative overflow-hidden rounded-xl px-ogs py-20 lg:py-36">
      <div className="grid grid-cols-12 gap-gs">
        <div className="col-span-12 lg:col-span-10 lg:col-start-2">
          <div className="w-full rounded-xl bg-white py-8 px-4 md:px-8 md:pt-6 md:pb-11">
            <HubspotForm
              portalId={process.env.GATSBY_FRANKIE_PORTAL_ID}
              formId={formId}
            />
          </div>
        </div>
      </div>
    </RevealWrapper>
  )
}

HubspotEmbed.propTypes = {
  data: PropTypes.shape({
    formId: PropTypes.string.isRequired,
  }),
}

export default HubspotEmbed
