import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { BLUE, PINK, YELLOW, TRANSPARENT, WHITE } from "../../../constants"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import AccordionItem from "./AccordionItem"
import "./AccordionPanel.css"

const AccordionPanel = ({ data }) => {
  const { heading, backgroundColour, items } = data
  const [hasImage, setHasImage] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = index => {
    setActiveIndex(index)
  }

  useEffect(() => {
    items.forEach(item => {
      if (item.image.length > 0) {
        setHasImage(true)
      }
    })
  })

  let imageData = {}
  if (items[activeIndex].image.length > 0) {
    imageData = {
      image: getImage(items[activeIndex].image[0].localFile),
      alt: items[activeIndex].image[0].title,
    }
  }

  let backgroundClass = ""
  switch (backgroundColour) {
    case BLUE:
      backgroundClass = "bg-blue-500"
      break
    case PINK:
      backgroundClass = "bg-pink-500"
      break
    case YELLOW:
      backgroundClass = "bg-yellow-500"
      break
    case TRANSPARENT:
      backgroundClass = "bg-transparent"
      break
    case WHITE:
      backgroundClass = "bg-white"
      break

    default:
      backgroundClass = "bg-white"
      break
  }

  return (
    <div
      className={`xl:grid xl:grid-cols-12 py-20 lg:py-14 px-ogs ${backgroundClass}`}
    >
      <div
        className={`${
          backgroundClass !== "bg-white" ? "rounded-2xl bg-white p-8" : ""
        } xl:col-span-10 xl:col-start-2 flex gap-x-gs`}
      >
        <div className={`w-full ${hasImage ? "lg:w-1/2" : "lg:w-full"}`}>
          <RevealWrapper className="relative z-10">
            {heading && (
              <h3
                className={`mb-10 lg:mb-16 text-center text-3xl lg:text-4xl font-semibold z-0 ${
                  hasImage ? "lg:text-left " : ""
                }`}
              >
                {heading}
              </h3>
            )}
            <div className="border-b border-blue-400">
              {items.length > 0 &&
                items.map((item, index) => {
                  return (
                    <React.Fragment key={`${item.heading} ${index}`}>
                      <AccordionItem
                        index={index}
                        item={item}
                        activeIndex={activeIndex}
                        handleClick={handleClick}
                      />
                    </React.Fragment>
                  )
                })}
            </div>
          </RevealWrapper>
        </div>
        {hasImage && (
          <div className="hidden lg:block max-w-md mx-auto lg:w-1/2">
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={activeIndex}
                timeout={200}
                classNames="fade-alt"
              >
                {imageData && (
                  <GatsbyImage
                    className="rounded-2xl object-cover w-full h-full"
                    image={imageData.image}
                    alt={imageData.alt}
                  />
                )}
              </CSSTransition>
            </SwitchTransition>
          </div>
        )}
      </div>
    </div>
  )
}

AccordionPanel.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    backgroundColour: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.arrayOf(PropTypes.shape()),
        heading: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.arrayOf(PropTypes.shape()),
      })
    ),
  }),
}

export default AccordionPanel
