import React from "react"
import PropTypes from "prop-types"

import { BLUE, PINK, YELLOW } from "../../../constants"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import Logomark from "../../SVG/Logomark"

const LeadText = ({ data }) => {
  const { heading, headingColour } = data

  const getHeadingClass = () => {
    switch (headingColour) {
      case PINK:
        return "text-pink-500"
      case BLUE:
        return "text-blue-500"
      case YELLOW:
        return "text-yellow-500"
      default:
        return "text-blue-500"
    }
  }

  return (
    <RevealWrapper className="px-ogs pt-32 pb-52 relative overflow-hidden lg:py-36">
      <div className="max-w-5xl mx-auto relative z-10">
        <h3
          className={`${getHeadingClass()} text-xl text-center sm:text-3xl md:text-4xl lg:text-5xl`}
        >
          {heading}
        </h3>
      </div>

      <div className="w-208 absolute -bottom-24 -left-44 transform rotate-12 text-white">
        <Logomark />
      </div>
    </RevealWrapper>
  )
}

LeadText.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    headingColour: PropTypes.string.isRequired,
  }),
}

export default LeadText
