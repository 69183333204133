import React from "react"
import PropTypes from "prop-types"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import VideoPlayer from "./VideoPlayer"
import "./Video.css"

const Video = ({ data }) => {
  const { heading, subheading, videoId, posterImage } = data
  return (
    <div className="text-center px-ogs py-6">
      {(heading || subheading) && (
        <div className="mx-auto max-w-2xl mb-10 pt-4 lg:pt-18 lg:mb-24">
          {heading && (
            <h4 className="font-semibold text-4xl mb-5">{heading}</h4>
          )}
          {subheading && <h3 className="text-xl font-medium">{subheading}</h3>}
        </div>
      )}

      <RevealWrapper className="rounded-xl lg:rounded-2xl overflow-hidden transform-gpu">
        <VideoPlayer videoId={videoId} posterImage={posterImage} />
      </RevealWrapper>
    </div>
  )
}

Video.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    videoId: PropTypes.string.isRequired,
    posterImage: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        localFile: PropTypes.shape(),
      })
    ).isRequired,
  }),
}

export default Video
