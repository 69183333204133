import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { BLUE, PINK, YELLOW, TRANSPARENT } from "../../../constants"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import OutlineView from "./OutlineView"
import CardView from "./CardView"

const FeaturesPanel = ({ data }) => {
  const { heading, backgroundColour, image, mobileImage, variant } = data

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      case TRANSPARENT:
        return ""

      default:
        return ""
    }
  }

  let imageData = {}
  if (image.length) {
    imageData = withArtDirection(getImage(image[0].localFile), [
      {
        media: "(max-width: 768px)",
        image: getImage(mobileImage[0].localFile),
      },
    ])
  }

  return (
    <div
      className={`${getBackgroundClass()} ${
        imageData.images ? "pt-6 lg:pt-24" : "py-6 lg:py-24"
      }`}
    >
      <RevealWrapper className="relative z-10">
        {variant === "card" ? (
          <CardView data={data} imageData={imageData} />
        ) : (
          <OutlineView data={data} imageData={imageData} />
        )}
      </RevealWrapper>
      {imageData.images && (
        <GatsbyImage
          className="pb-5:6 h-0 w-full md:h-auto md:pb-0"
          image={imageData}
          alt={heading}
        />
      )}
    </div>
  )
}

FeaturesPanel.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    backgroundColour: PropTypes.string,
    image: PropTypes.array.isRequired,
    mobileImage: PropTypes.array.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
}

export default FeaturesPanel
