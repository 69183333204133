import React from "react"
import PropTypes from "prop-types"

import PartnerCard from "./PartnerCard"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const PartnerCards = ({ data }) => {
  const { heading, partners } = data

  return (
    <div className="px-ogs pt-20 pb-28 lg:pt-32 lg:pb-40 PartnerCards">
      <h5 className="mb-8 text-charcoal-500 font-semibold text-3xl text-center lg:mb-12 lg:text-4xl">
        {heading}
      </h5>
      <div className="flex flex-col items-center gap-6 md:flex-row md:flex-wrap md:justify-center md:items-stretch md:gap-5">
        {partners.map((partner, index) => {
          return (
            <RevealWrapper
              key={partner.id + index}
              delay={index * 250}
              className="max-w-xs"
            >
              <PartnerCard data={partner} />
            </RevealWrapper>
          )
        })}
      </div>
    </div>
  )
}

PartnerCards.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    partners: PropTypes.array.isRequired,
  }),
}

export default PartnerCards
