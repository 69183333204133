import React from "react"

const IconChevronLeft = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 6 10">
      <path d="M4.35355 0l.70711.707107L1.41421 4.35355 5.06066 8l-.70711.70711L0 4.35355 4.35355 0z" />
    </svg>
  )
}

export default IconChevronLeft
