import React from "react"
import PropTypes from "prop-types"

import HeroCards from "./HeroCards/HeroCards"
import TextAndImages from "./TextAndImages/TextAndImages"
import FeaturesPanel from "./FeaturesPanel/FeaturesPanel"
import FeatureCards from "./FeatureCards/FeatureCards"
import Features from "./Features/Features"
import ServicesCarousel from "./ServicesCarousel/ServicesCarousel"
import BackgroundGradient from "../BackgroundGradient/BackgroundGradient"
import Video from "./Video/Video"
import CallToAction from "./CallToAction/CallToAction"
import ImageBanner from "./ImageBanner/ImageBanner"
import ReviewsCarousel from "./Reviews/ReviewsCarousel"
import BudgetGraph from "./BudgetGraph/BudgetGraph"
import TextBlock from "./TextBlock/TextBlock"
import PartnerCards from "./PartnerCards/PartnerCards"
import Pricing from "./Pricing/Pricing"
import BCorp from "./BCorp/BCorp"
import ServiceDetail from "./ServiceDetail/ServiceDetail"
import AccordionPanel from "./AccordionPanel/AccordionPanel"
import ContentColumns from "./ContentColumns/ContentColumns"
import LeadHeading from "./LeadHeading/LeadHeading"
import LeadText from "./LeadText/LeadText"
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm"
import CurrentStatus from "./CurrentStatus/CurrentStatus"
import ContactUs from "./ContactUs/ContactUs"
import LinkCards from "./LinkCards/LinkCards"
import InformationGrid from "./InformationGrid/InformationGrid"
import CareProfessionals from "./CareProfessionals/CareProfessionals"
import HubspotEmbed from "./HubspotEmbed/HubspotEmbed"
import RichText from "./RichText/RichText"

const FlexibleContent = ({ flexibleContent }) => {
  if (flexibleContent.length === 0) return null
  const renderFlexibleContentComponent = (typeHandle, item, index) => {
    switch (typeHandle) {
      case "textAndImages":
        const reduceTopSpacing =
          flexibleContent[index - 1]?.typeHandle === "textAndImages"
        const reduceBottomSpacing =
          flexibleContent[index + 1]?.typeHandle === "textAndImages"
            ? true
            : false
        return (
          <TextAndImages
            data={item}
            reduceTopSpacing={reduceTopSpacing}
            reduceBottomSpacing={reduceBottomSpacing}
          />
        )
      case "featuresPanel":
        return <FeaturesPanel data={item} />
      case "servicesCarousel":
        return <ServicesCarousel data={item} />
      case "featureCards":
        return <FeatureCards data={item} />
      case "features":
        return <Features data={item} />
      case "heroCards":
        return <HeroCards data={item} />
      case "reviewsCarousel":
        return <ReviewsCarousel data={item} />
      case "budgetGraph":
        return <BudgetGraph data={item} />
      case "pricing":
        return <Pricing data={item} />
      case "video":
        return <Video data={item} />
      case "callToAction":
        return <CallToAction data={item} />
      case "bCorp":
        return <BCorp data={item} />
      case "richText":
        return (
          <div className="mx-ogs py-16 lg:py-24 2xl:py-32 grid md:grid-cols-12 md:gap-x-gs">
            <div className="md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3 space-y-12 lg:space-y-20">
              <RichText data={item} />
            </div>
          </div>
        )
      case "textBlock":
        const reduceBottomSpacingTextBlock =
          flexibleContent[index + 1]?.typeHandle === "featuresPanel"
        return (
          <TextBlock
            data={item}
            reduceBottomSpacing={reduceBottomSpacingTextBlock}
          />
        )
      case "imageBanner":
        const reduceMobileTopSpacing =
          flexibleContent[index - 1]?.typeHandle === "featureCards"
        return (
          <ImageBanner
            data={item}
            reduceMobileTopSpacing={reduceMobileTopSpacing}
          />
        )
      case "partnerCards":
        return <PartnerCards data={item} />
      case "serviceDetail":
        return <ServiceDetail data={item} />
      case "accordionPanel":
        return <AccordionPanel data={item} />
      case "contentColumns":
        const reduceTopSpacingContentColumns =
          flexibleContent[index - 1]?.typeHandle === "textAndImages"
        return (
          <ContentColumns
            data={item}
            reduceTopSpacing={reduceTopSpacingContentColumns}
          />
        )
      case "leadHeading":
        return <LeadHeading data={item} />
      case "leadText":
        return <LeadText data={item} />
      case "subscriptionForm":
        return <SubscriptionForm data={item} />
      case "currentStatus":
        return <CurrentStatus data={item} />
      case "contactUs":
        return <ContactUs data={item} />
      case "linkCards":
        return <LinkCards data={item} />
      case "informationGrid":
        return <InformationGrid data={item} />
      case "careProfessionals":
        return <CareProfessionals data={item} />
      case "hubspotEmbed":
        return <HubspotEmbed data={item} />
      default:
        return
    }
  }
  return (
    <>
      {flexibleContent.map((item, index) => {
        return (
          <div
            key={item.id + index}
            className={`relative ${!item.backgroundGradient ? "" : "bg-white"}`}
          >
            {(item.backgroundGradient === "top" ||
              item.backgroundGradient === "bottom") && (
              <BackgroundGradient gradient={item.backgroundGradient} />
            )}

            <div
              className={`relative ${
                item.typeHandle === "featureCards" ? "z-20" : "z-10"
              }`}
            >
              {renderFlexibleContentComponent(item.typeHandle, item, index)}
            </div>
          </div>
        )
      })}
    </>
  )
}

FlexibleContent.propTypes = {
  flexibleContent: PropTypes.array.isRequired,
}

export default FlexibleContent
