import React from "react"
import PropTypes from "prop-types"

const LinkCard = ({ card, className }) => {
  return (
    <div className="text-xl lg:text-2xl font-semibold md:py-6 ">
      <div
        className={`rte rte--link-card rounded-xl px-15 py-10 xl:px-20 2xl:px-24 2xl:py-20 min-h-80 lg:min-h-none text-center flex items-center justify-center h-full ${className}`}
        dangerouslySetInnerHTML={{ __html: card }}
      />
    </div>
  )
}

LinkCard.propTypes = {
  card: PropTypes.string,
  className: PropTypes.string,
}

export default LinkCard
