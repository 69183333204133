import PropTypes from "prop-types"
import React, { useRef, useState, useLayoutEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import IconBCorp from "../../SVG/IconBCorp"

gsap.registerPlugin(ScrollTrigger)

const BCorp = ({ data }) => {
  const { heading, lead, body } = data

  const wrapperRef = useRef()
  const item = gsap.utils.selector(wrapperRef)
  const tl = useRef()

  const [hasLoaded, setHasLoaded] = useState(false)

  useLayoutEffect(() => {
    setHasLoaded(true)

    ScrollTrigger.matchMedia({
      "(max-width: 1023px)": function () {
        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: wrapperRef.current,
            start: item(".part-one").offsetHeight / 2,
            end: () => "+=" + wrapperRef.current.offsetWidth,
            scrub: true,
            pin: true,
          },
        })
        tl.current.fromTo(item(".part-one"), { opacity: 1 }, { opacity: 0 })
        tl.current.fromTo(item(".part-two"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-three"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-four"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-five"), { opacity: 0 }, { opacity: 1 })
      },
      "(min-width: 1024px)": function () {
        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: wrapperRef.current,
            start: "center center",
            end: () => "+=" + wrapperRef.current.offsetWidth,
            scrub: true,
            pin: true,
          },
        })
        tl.current.fromTo(item(".part-one"), { opacity: 1 }, { opacity: 0 })
        tl.current.fromTo(item(".part-two"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-three"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-four"), { opacity: 0 }, { opacity: 1 })
        tl.current.fromTo(item(".part-five"), { opacity: 0 }, { opacity: 1 })
      },
    })
    return () => {
      ScrollTrigger.kill()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      ref={wrapperRef}
      className="bg-blue-400 px-ogs py-12 lg:py-24 3xl:py-48 relative min-h-screen lg:min-h-0"
    >
      <div className="part-one absolute inset-0 h-screen lg:h-auto flex justify-center items-center pointer-events-none">
        <div className="w-30 lg:w-44 text-white">
          <IconBCorp />
        </div>
      </div>
      <div
        className={`flex justify-center items-center h-full ${
          hasLoaded ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="space-y-5 lg:space-y-8 lg:grid lg:grid-cols-4 max-w-xl mx-auto">
          {heading && (
            <div className="part-two lg:col-span-4 pr-12">
              <h3 className="text-3xl lg:text-4xl">{heading}</h3>
            </div>
          )}
          {lead && (
            <div
              className="part-three border-t-2 border-white rte pt-5 lg:pt-8 lg:col-span-4 pr-3 lg:pr-0"
              dangerouslySetInnerHTML={{ __html: lead }}
            />
          )}
          <div className="part-four border-t-2 border-white lg:col-span-4 lg:grid lg:grid-cols-5 space-y-5 lg:space-y-0">
            {body && (
              <div
                className="rte pt-5 lg:pt-8 lg:col-span-4 pr-3 lg:pr-6"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
            <div className="part-five pt-5 lg:pt-9 lg:col-span-1 border-t-2 lg:border-0 lg:border-l-2 border-white">
              <div className="w-15 h-24 lg:mx-auto">
                <IconBCorp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BCorp.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }),
}

export default BCorp
