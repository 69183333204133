import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import CareProfessionalsCard from "./CareProfessionalsCard"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"
import CareProfessionalsControls from "./CareProfessionalControls"
import Button from "../../Button/Button"

import { BLUE, NONE, PINK, WHITE, YELLOW } from "../../../constants"

import Logomark from "../../SVG/Logomark"

const CareProfessionals = ({ data }) => {
  const [careProfs, setCareProfs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const careProfsCarousel = useRef(null)

  const next = () => {
    careProfsCarousel.current.swiper.slideNext()
  }

  const previous = () => {
    careProfsCarousel.current.swiper.slidePrev()
  }

  const {
    heading,
    hoursSinceCreationDisplay,
    backgroundColour,
    unityMark,
    button,
  } = data

  const buttonStyle = backgroundColour === BLUE ? "secondary" : "primary"
  const today = new Date()
  const hoursAgo = new Date()
  hoursAgo.setHours(today.getHours() - hoursSinceCreationDisplay)

  const unityMarkStyle =
    backgroundColour === WHITE || backgroundColour === NONE
      ? "text-blue-500 opacity-10"
      : "text-white opacity-20"

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case NONE:
        return ""
      case WHITE:
        return "bg-white"
      case PINK:
        return "bg-pink-500"
      case BLUE:
        return "bg-blue-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return ""
    }
  }

  useEffect(() => {
    makeRequest()
    /* eslint-disable-next-line */
  }, [])

  const makeRequest = () => {
    setIsLoading(true)
    const reqUrl =
      `${process.env.GATSBY_FRANKIE_SEARCH_URL}` +
      `?filter[activated_between]=${hoursAgo.toISOString()},${today.toISOString()}` +
      `&sort=randomised&latitude=1.1234&longitude=1.1234`

    axios
      .get(reqUrl)
      .then(res => {
        const data = res.data.data.map(prof => {
          return {
            ...prof,
            languages: [prof.primary_language, prof.secondary_language],
          }
        })
        setCareProfs(data)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className={`${getBackgroundClass()} relative overflow-hidden`}>
      {careProfs.length > 0 && (
        <div
          className={`px-0 text-center ${
            unityMark ? "py-24 lg:py-30" : "py-18 lg:py-24"
          } relative z-10`}
        >
          {heading && (
            <div className="mb-8 px-ogs md:gap-gs lg:grid lg:grid-cols-12">
              <div className="text-left lg:col-span-7 lg:col-start-2">
                <h3 className="text-4xl font-semibold text-charcoal-500">
                  {heading}
                </h3>
              </div>
            </div>
          )}
          <RevealWrapper>
            <Swiper
              ref={careProfsCarousel}
              slidesPerView="auto"
              freeMode={true}
              grabCursor
            >
              {!isLoading ? (
                careProfs.map((careProf, index) => (
                  <SwiperSlide
                    key={index}
                    className={`mr-gs pl-0 lg:mr-0 lg:pl-7 ${
                      index === 0 ? "ml-ogs lg:pl-col" : ""
                    }`}
                  >
                    <CareProfessionalsCard
                      careProf={careProf}
                      key={careProf.id}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </Swiper>
            <div className="mt-8 grid grid-cols-12 gap-gs px-ogs">
              <CareProfessionalsControls next={next} previous={previous} />
              {button && (
                <div className="col-span-6 col-start-4 flex justify-center">
                  <Button button={button} buttonStyle={buttonStyle} />
                </div>
              )}
            </div>
          </RevealWrapper>
        </div>
      )}
      {unityMark && careProfs.length > 0 && (
        <div
          className={`${unityMarkStyle} absolute -bottom-10 -left-24 h-48 rotate-12 transform lg:-bottom-20 lg:-left-48 lg:h-96`}
        >
          <Logomark />
        </div>
      )}
    </div>
  )
}

CareProfessionals.propTypes = {
  data: PropTypes.shape({
    backgroundColour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unityMark: PropTypes.bool,
  }),
}

export default CareProfessionals
