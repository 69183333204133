import React from "react"
import TransitionLinkFade from "../../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BLUE, PINK, YELLOW } from "../../../constants"

import Signature from "../../Signature/Signature"

const HeroCard = ({ data, borderRadius, columns }) => {
  const {
    heading,
    headingLink,
    body,
    backgroundColour,
    image,
    imagePosition,
    signatureName,
    signatureText,
    signatureTextLink,
    signaturePosition,
  } = data

  let backgroundClass = ""
  switch (backgroundColour) {
    case BLUE:
      backgroundClass = "bg-blue-500 text-white"
      break
    case PINK:
      backgroundClass = "bg-pink-500"
      break
    case YELLOW:
      backgroundClass = "bg-yellow-500"
      break
    default:
      break
  }

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].alt || "",
    }
  }

  const cardClasses = `${borderRadius} ${backgroundClass} overflow-hidden h-full transform-gpu flex transition hover:opacity-100 ${
    imagePosition === "top" ? "flex-col-reverse justify-end" : "flex-col"
  } ${headingLink.url ? "hover:bg-opacity-70" : ""}`

  const contentClassses = body
    ? "flex-1 px-10 py-14 xl:p-16 2xl:p-24"
    : "flex-1 flex flex-col items-center justify-center px-4 py-6 lg:py-8 lg:px-6"

  const headingSizeClasses =
    columns === "two" ? "text-2xl lg:text-xl xl:text-3xl" : "text-2xl"

  const signaturePositionClasses =
    signaturePosition === "topRight"
      ? "right-0 lg:pt-12 w-1/2 flex items-center lg:items-start justify-end"
      : "flex items-center right-0 lg:right-auto lg:pl-7"

  const signatureWrapperClasses = `text-white absolute z-20 inset-y-0 pr-2 py-4 pl-4 sm:pr-4 ${signaturePositionClasses}`

  const signatureScreenClasses = `${
    signaturePosition === "topRight"
      ? "bg-gradient-to-l"
      : "bg-gradient-to-l lg:bg-gradient-to-r"
  } from-charcoal-500 opacity-50 absolute inset-0`

  const LinkWrapper = ({ children }) => {
    if (headingLink.url) {
      if (headingLink.type === "entry") {
        return (
          <TransitionLinkFade
            className="block h-full"
            to={`/${headingLink.element?.uri}`}
          >
            {children}
          </TransitionLinkFade>
        )
      } else {
        return (
          <a
            className="block h-full"
            href={headingLink.url}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        )
      }
    }

    return children
  }

  const CardInner = () => {
    return (
      <div className={cardClasses}>
        <div className={contentClassses}>
          {heading && <h3 className={headingSizeClasses}>{heading}</h3>}

          {body !== null && (
            <div
              className="rte mt-4"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          )}
        </div>
        {imageData.image && (
          <div className="relative">
            {(signatureText || signatureName) && (
              <div className={signatureWrapperClasses}>
                <div className={signatureScreenClasses}></div>
                <Signature
                  text={signatureText}
                  name={signatureName}
                  link={signatureTextLink}
                  className="w-36 relative z-10"
                />
              </div>
            )}
            <GatsbyImage
              className="w-full h-0 pb-1:1 relative"
              image={imageData.image}
              alt={imageData.alt}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <LinkWrapper>
      <CardInner />
    </LinkWrapper>
  )
}

HeroCard.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    body: PropTypes.string,
    headingLink: PropTypes.shape(),
    backgroundColour: PropTypes.string,
    image: PropTypes.array,
    imagePosition: PropTypes.string,
    signaturePosition: PropTypes.string,
    signatureName: PropTypes.string,
    signatureText: PropTypes.string,
  }),
  columns: PropTypes.string,
  borderRadius: PropTypes.string,
}

export default HeroCard
