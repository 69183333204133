import React from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"

const Button = ({ button, buttonSize, buttonStyle }) => {
  const { type, text, target, url, element } = button

  if (type === "url") {
    return (
      <a
        href={url}
        target={target}
        className={`Button ${
          buttonStyle === "secondary" ? "Button--secondary" : "Button--primary"
        } Button--larger ${buttonSize === "larger" ? "Button--text-lg" : ""}`}
      >
        {text}
      </a>
    )
  }

  if (type === "entry") {
    return (
      <TransitionLinkFade
        to={`/${element.uri}`}
        className={`Button ${
          buttonStyle === "secondary" ? "Button--secondary" : "Button--primary"
        } Button--larger ${buttonSize === "larger" ? "Button--text-lg" : ""}`}
      >
        {text}
      </TransitionLinkFade>
    )
  }

  return <></>
}

Button.propTypes = {
  button: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string,
    element: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
}

export default Button
