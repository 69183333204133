import React from "react"
import PropTypes from "prop-types"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const FeatureCards = ({ data }) => {
  const { heading, text, cards } = data

  return (
    <div className="px-ogs grid lg:grid-cols-12 gap-gs text-center my-8 lg:mt-12 relative z-10 lg:mb-12">
      <div className="lg:col-span-12 2xl:col-start-2 2xl:col-span-10 lg:border-3 lg:border-white rounded-xl lg:pt-15">
        <div className="max-w-3xl mx-auto">
          {heading && (
            <h3 className="font-semibold text-3xl lg:text-4xl mb-5 px-5 lg:px-0">
              {heading}
            </h3>
          )}
          {text && <h6 className="sm:px-8 text-xl">{text}</h6>}
        </div>
        {cards.length > 0 && (
          <div className="mt-15 lg:mb-8 space-y-6 lg:space-y-0 lg:px-8 xl:flex xl:flex-wrap xl:justify-center xl:-mx-8">
            {cards.map((item, index) => {
              const { heading, text } = item
              return (
                <RevealWrapper
                  key={heading + index}
                  delay={index * 250}
                  innerClassName="h-full"
                  className={`max-w-md mx-auto xl:mx-0 xl:max-w-none xl:w-1/3 lg:py-6 xl:px-8 ${
                    (index + 1) % 3 !== 1 ? "xl:border-l-3 xl:border-white" : ""
                  }`}
                >
                  <div className="bg-white rounded-xl px-4 py-10 lg:px-10 xl:px-15 2xl:py-15 h-full flex flex-col items-center justify-center">
                    {heading && (
                      <h4 className="font-semibold text-blue-500 mb-6 text-2xl">
                        {heading}
                      </h4>
                    )}
                    {text && <div className="px-4 lg:px-0">{text}</div>}
                  </div>
                </RevealWrapper>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

FeatureCards.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default FeatureCards
