import React from "react"
import PropTypes from "prop-types"

import { BLUE, PINK, YELLOW } from "../../../constants"

const LeadHeading = ({ data }) => {
  const { heading, headingColour, text } = data

  const getHeadingClass = () => {
    switch (headingColour) {
      case PINK:
        return "text-pink-500"
      case BLUE:
        return "text-blue-500"
      case YELLOW:
        return "text-yellow-500"
      default:
        return "text-blue-500"
    }
  }

  return (
    <div className="relative overflow-hidden px-ogs pt-10 pb-6 lg:py-40">
      <div className="relative z-10 mx-auto max-w-5xl text-center">
        <h2 className={`${getHeadingClass()} mb-6 text-4xl md:text-5xl`}>
          {heading}
        </h2>
        <p className="mx-auto max-w-xl md:max-w-2xl lg:max-w-3xl">{text}</p>
      </div>

      <div className="absolute inset-0 text-white">
        <svg
          className="hidden fill-current md:block md:h-full md:w-full"
          viewBox="0 0 1440 568"
          preserveAspectRatio="none"
        >
          <path d="M-2.00858 568.279L-2.00861 25.0246C501.56 -35.6161 1044.8 153.168 1440.45 568.279L-2.00858 568.279Z" />
        </svg>

        <svg
          className="h-full w-full fill-current md:hidden"
          viewBox="0 0 375 486"
          preserveAspectRatio="none"
        >
          <path d="M0.000464311 486.279C-1.19951 486.279 -0.499554 162.633 0.000432602 0.80965C128.704 38.9361 254.749 92.7346 375 161.818L375 486.279L0.000464311 486.279Z" />
        </svg>
      </div>
    </div>
  )
}

LeadHeading.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    headingColour: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default LeadHeading
