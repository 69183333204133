import React from "react"
import PropTypes from "prop-types"

const ReviewsCard = ({ item }) => {
  const { heading, subheading, text } = item
  return (
    <div className="ReviewsCard">
      {heading && <h3 className="ReviewsCard__heading">{heading}</h3>}
      {text && <div>{text}</div>}
      {subheading && <h4 className="ReviewsCard__subheading">{subheading}</h4>}
    </div>
  )
}

ReviewsCard.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
}

export default ReviewsCard
