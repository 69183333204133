import React from "react"
import PropTypes from "prop-types"

const BudgetGraphRateCircle = ({
  heading,
  rate,
  subheading,
  background = "",
  rateSavings,
}) => {
  return (
    <div
      className={`border-2 border-blue-500 rounded-full w-56 h-56 xs:w-64 xs:h-64 lg:w-80 lg:h-80 mx-auto py-4 px-8 lg:px-18 flex flex-col items-center justify-center mb-10 ${background} relative`}
    >
      <div className="font-semibold">
        <div className="text-xl my-2 lg:mt-0 lg:mb-3 leading-7">{heading}</div>
        <div className="text-4xl mb-1 font-bold">{rate}</div>
        <div>{subheading}</div>
      </div>
      {rateSavings && (
        <div
          className={`border-2 border-blue-500 font-bold w-20 h-20 px-2 md:w-24 leading-4 md:h-24 rounded-full lg:w-36 lg:h-36 mx-auto md:px-2 lg:px-2 lg:text-lg bg-white lg:leading-6 absolute flex items-center -ml-14 -top-3 md:-ml-8 text-black md:top-12 text-xs left-full lg:-ml-12`}
        >
          {rateSavings}
        </div>
      )}
    </div>
  )
}

BudgetGraphRateCircle.propTypes = {
  heading: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  background: PropTypes.string,
  rateSavings: PropTypes.string,
}

export default BudgetGraphRateCircle
