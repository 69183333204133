import React from 'react'

const IconDollar = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 20 20">
      <path d="M10.3 9.6V3.1c1.5.3 2.7 1.6 2.7 3.2 0 .3.2.5.5.5s.5-.2.5-.5c0-2.2-1.6-3.9-3.7-4.2V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v1.6c-2.1.3-3.7 2-3.7 4.2s1.6 3.9 3.7 4.2v6.4c-1.5-.2-2.7-1.6-2.7-3.2 0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 2.2 1.6 3.9 3.7 4.2v1.6c0 .3.2.5.5.5s.5-.2.5-.5v-1.6c2.1-.3 3.7-2 3.7-4.2s-1.6-3.9-3.7-4.1zM6.6 6.3c0-1.6 1.2-2.9 2.7-3.2v6.3c-1.5-.2-2.7-1.5-2.7-3.1zm3.7 10.6v-6.3c1.5.2 2.7 1.6 2.7 3.2 0 1.5-1.2 2.8-2.7 3.1z" />
    </svg>
  )
}

export default IconDollar
