import React from "react"

import { BLACK, BLUE, NONE, PINK, WHITE, YELLOW } from "../../../constants"

import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const TextBlock = ({ data, reduceBottomSpacing }) => {
  const { header, headerTextColour, text, textColour, backgroundColour } = data

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case NONE:
        return ""
      case WHITE:
        return "bg-white"
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return "bg-blue-500"
    }
  }

  const getHeaderClass = () => {
    switch (headerTextColour) {
      case BLACK:
        return "text-charcoal"
      case WHITE:
        return "text-white"
      default:
        return "text-charcoal"
    }
  }

  const getTextClass = () => {
    switch (textColour) {
      case BLACK:
        return "text-charcoal"
      case WHITE:
        return "text-white"
      default:
        return "text-white"
    }
  }

  return (
    <div className={`${getBackgroundClass()} px-ogs`}>
      <RevealWrapper
        className={`${
          reduceBottomSpacing ? "pb-18" : "pb-24"
        } pt-24 text-center font-semibold mx-auto max-w-5xl`}
      >
        {header && (
          <h3 className={`${getHeaderClass()} text-4xl mb-8 font-medium`}>
            {header}
          </h3>
        )}
        {text && <p className={`${getTextClass()} text-md mb-8`}>{text}</p>}
      </RevealWrapper>
    </div>
  )
}

export default TextBlock
