import React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import PricingCard from "./PricingCard"

const PricingCards = ({ cards }) => {
  return (
    <div className="pb-8 lg:pb-7">
      <Swiper
        slidesPerView="auto"
        freeMode={true}
        grabCursor
        breakpoints={{
          840: {
            enabled: false,
          },
        }}
      >
        {cards.map((item, index) => {
          let spacingClasses = ""

          if (index === 0) {
            spacingClasses = "ml-6 mr-3 xl:ml-0 xl:mr-4"
          } else if (index === 1) {
            spacingClasses = "mx-3 xl:mx-4"
          } else {
            spacingClasses = "mr-6 ml-3 xl:mr-0 xl:ml-4"
          }

          return (
            <SwiperSlide
              key={`${item.heading} ${item.packageValue}`}
              className={`min-w-58 flex-1 ${spacingClasses}`}
            >
              <PricingCard data={item} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

PricingCards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      packageValue: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          serviceCategory: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              colour: PropTypes.string,
            })
          ),
        })
      ),
      hoursWeek: PropTypes.string,
    })
  ),
}

export default PricingCards
