import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import Signature from "../../Signature/Signature"

const TextAndImagesImageDouble = ({
  images,
  imageOffset,
  signatureName,
  signatureText,
}) => {
  const imageData = [
    {
      image: getImage(images[0]?.localFile),
      alt: images[0]?.alt || "",
    },
    {
      image: getImage(images[1]?.localFile),
      alt: images[1]?.alt || "",
    },
  ]

  const wrapperClasses = imageOffset
    ? "grid grid-cols-2 md:grid-cols-7 gap-x-gs md:gap-x-8"
    : "grid grid-cols-2 gap-gs"

  const imagePrimaryWrapperClasses = imageOffset
    ? "col-span-1 md:col-span-4 lg:flex lg:flex-col"
    : ""
  const imagePrimaryClasses = `${
    imageOffset ? "-ml-32 md:ml-0" : ""
  } rounded-4xl overflow-hidden transform-gpu w-full`

  const imageSecondaryWrapperClasses = imageOffset
    ? "col-span-1 md:col-span-3 self-end xl:pt-24"
    : ""
  const imageSecondaryClasses = `${
    imageOffset ? "-mr-16 md:mr-0" : ""
  } rounded-4xl overflow-hidden transform-gpu w-full`

  return (
    <div className={wrapperClasses}>
      <div className={imagePrimaryWrapperClasses}>
        {imageData[0]?.image && (
          <GatsbyImage
            className={imagePrimaryClasses}
            image={imageData[0].image}
            alt={imageData[0].alt}
          />
        )}
        {imageOffset && (
          <div className="mt-8 lg:mt-10 xl:mt-auto lg:ml-auto lg:w-52">
            <Signature
              text={signatureText}
              name={signatureName}
              className="text-center lg:text-right"
            />
          </div>
        )}
      </div>
      <div className={imageSecondaryWrapperClasses}>
        {imageData[1]?.image && (
        <GatsbyImage
          className={imageSecondaryClasses}
          image={imageData[1].image}
          alt={imageData[1].alt}
        />)}
      </div>
    </div>
  )
}

TextAndImagesImageDouble.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      localFile: PropTypes.shape(),
    })
  ),
  imageOffset: PropTypes.bool,
  signatureText: PropTypes.string,
  signatureName: PropTypes.string,
}

export default TextAndImagesImageDouble
