import React from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import "./ServicesCard.css"

const ServicesCard = ({ data, className }) => {
  const { title, body, imagePrimary, slug } = data

  let imageData = {}
  if (imagePrimary.length) {
    imageData = {
      image: getImage(imagePrimary[0].localFile),
      alt: imagePrimary[0].alt || title,
    }
  }
  return (
    <div className={`ServicesCard ${className}`}>
      <TransitionLinkFade
        to={`/services/${slug}`}
        className="ServicesCard__head"
      >
        <div className="ServicesCard__image-screen"></div>
        <GatsbyImage
          image={imageData.image}
          alt={imageData.alt}
          loading="eager"
        />
      </TransitionLinkFade>
      <div className="ServicesCard__main">
        <TransitionLinkFade
          to={`/services/${slug}`}
          className="ServicesCard__heading-wrapper"
        >
          <h3 className="ServicesCard__heading">{title}</h3>
        </TransitionLinkFade>
        <div
          className="ServicesCard__body"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>

        <div className="ServicesCard__link-wrapper">
          <TransitionLinkFade
            to={`/services/${slug}`}
            className="ServicesCard__link"
          >
            Learn more
          </TransitionLinkFade>
        </div>
      </div>
    </div>
  )
}

ServicesCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    imagePrimary: PropTypes.array.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
}

export default ServicesCard
