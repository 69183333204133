import React from "react"
import PropTypes from "prop-types"

import Button from "../../Button/Button"
import FeaturesItems from "./FeaturesItems"

const Features = ({ data }) => {
  const { heading, subheading, items, style, button, buttonSize } = data

  let wrapperClasses = ""
  let headingClasses = ""
  let subheadingClasses = ""

  if (style === "default") {
    wrapperClasses = "py-24 lg:py-32"
    headingClasses = "text-3xl font-semibold lg:text-4xl mb-10 lg:mb-8"
    subheadingClasses = "font-semibold mb-10 lg:mb-15"
  } else {
    wrapperClasses = "pt-24 pb-20 lg:pb-52 lg:pt-40 bg-white"
    headingClasses = "font-semibold text-3xl lg:text-4xl mb-8"
    subheadingClasses = "text-xl font-medium mb-15 lg:mb-20"
  }

  return (
    <div className={`text-center ${wrapperClasses}`}>
      {(heading || subheading) && (
        <div className="md:w-2/3 md:mx-auto px-ogs">
          {heading && <h3 className={headingClasses}>{heading}</h3>}
          {subheading && <h4 className={subheadingClasses}>{subheading}</h4>}
        </div>
      )}

      {items.length > 0 && <FeaturesItems items={items} style={style} />}

      {button.url !== null && (
        <div className="px-ogs mt-10">
          <div className="inline-block">
            <Button button={button} buttonSize={buttonSize} />
          </div>
        </div>
      )}
    </div>
  )
}

Features.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    style: PropTypes.string,
    buton: PropTypes.shape(),
    butonSize: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
}

export default Features
