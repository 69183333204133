import React from "react"
import PropTypes from "prop-types"

import Logomark from "../../SVG/Logomark"
import BudgetGraphIndustry from "./BudgetGraphIndustry"
import BudgetGraphFrankie from "./BudgetGraphFrankie"

const BudgetGraph = ({ data }) => {
  const {
    frankieRate,
    frankieRateCaption,
    frankieRateHeading,
    frankieRateSavings,
    heading,
    industryRate,
    industryRateCaption,
    subheading,
    unityMarkHeading,
  } = data

  return (
    <div className="grid grid-cols-4 gap-x-gs px-ogs py-24 lg:grid-cols-12 lg:pb-30 bg-blue-500">
      {unityMarkHeading && (
        <div className="col-span-4 xs:col-span-4 lg:col-span-full lg:mb-4 xl:col-start-2">
          <div className="items-baseline space-y-6 md:flex md:space-y-4 md:space-x-4 mb-10">
            <div className="h-7 w-12 text-white">
              <Logomark />
            </div>
            <h2 className="font-semibold text-3xl lg:text-4xl text-white">
              {unityMarkHeading}
            </h2>
          </div>
        </div>
      )}
      {(heading || subheading) && (
        <div className="col-span-4 mb-16 md:col-span-full text-white col-start-1 sm:col-span-full sm:px-0 lg:col-span-full lg:col-start-2 2xl:col-span-full 2xl:col-start-3">
          {heading && (
            <h2 className="text-3xl font-semibold lg:text-4xl">{heading}</h2>
          )}
          {subheading && <h4 className="pt-1">{subheading}</h4>}
        </div>
      )}
      <div className="col-span-4 space-y-gs text-center md:flex md:space-y-0 md:space-x-7 lg:col-span-12 xl:col-span-10 xl:col-start-2">
        <BudgetGraphIndustry
          industryRate={industryRate}
          industryRateCaption={industryRateCaption}
        />
        <BudgetGraphFrankie
          frankieRateHeading={frankieRateHeading}
          frankieRateCaption={frankieRateCaption}
          frankieRate={frankieRate}
          frankieRateSavings={frankieRateSavings}
        />
      </div>
    </div>
  )
}

BudgetGraph.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    unityMarkHeading: PropTypes.string,
    frankieRate: PropTypes.string,
    frankieRateCaption: PropTypes.string,
    frankieRateHeading: PropTypes.string,
    frankieRateSavings: PropTypes.string,
    industryRate: PropTypes.string,
    industryRateCaption: PropTypes.string,
    backgroundGradient: PropTypes.string,
  }),
}

export default BudgetGraph
