import React from "react"

const IconPinSmall = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 20 20">
      <path d="M10.5 20c-.2 0-.3-.1-.4-.2-.3-.4-6.3-9.5-6.3-13.1C3.8 3 6.8 0 10.5 0s6.7 3 6.7 6.7c0 3.5-6 12.7-6.3 13.1-.1.1-.2.2-.4.2zm0-19C7.3 1 4.8 3.6 4.8 6.7c0 2.7 4.3 9.7 5.7 11.9 1.4-2.2 5.7-9.2 5.7-11.9 0-3.1-2.6-5.7-5.7-5.7zm0 8.7c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3 1.6 0 3 1.3 3 3 0 1.6-1.4 3-3 3zm0-5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>

  )
}

export default IconPinSmall
