import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PartnerCard = ({ data }) => {
  const { logo, body, websiteLink } = data

  const imageData = {
    image: getImage(logo[0].localFile),
    alt: logo[0].alt || "",
  }

  return (
    <a
      className="px-4 pt-15 pb-9 rounded-2xl bg-white text-center hover:opacity-100 lg:bg-transparent lg:hover:bg-white transition duration-700 block"
      href={websiteLink}
      target="_blank"
      rel="noreferrer"
    >
      <GatsbyImage
        className="w-48 h-32 max-w-full mb-5"
        image={imageData.image}
        alt={imageData.alt}
        imgClassName="w-full h-full"
        objectFit="scale-down"
      />
      <p>{body}</p>
    </a>
  )
}

PartnerCard.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.array,
    body: PropTypes.string,
    websiteLink: PropTypes.string,
  }),
}

export default PartnerCard
