import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import "swiper/swiper-bundle.css"

import ServicesCard from "../../Services/ServicesCard"
import ServicesCarouselControls from "./ServicesCarouselControls"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"
SwiperCore.use([Navigation])

const ServicesCarousel = ({ data }) => {
  const { heading, services } = data
  const servicesCarousel = useRef(null)

  const next = () => {
    servicesCarousel.current.swiper.slideNext()
  }

  const previous = () => {
    servicesCarousel.current.swiper.slidePrev()
  }

  return (
    <div className="mt-24 lg:mt-40">
      {heading && (
        <div className="mb-8 px-ogs md:grid md:grid-cols-12 md:gap-gs">
          <div className="md:col-span-10 lg:col-start-2">
            <h3 className="text-3xl lg:text-4xl font-semibold text-charcoal-500">
              {heading}
            </h3>
          </div>
        </div>
      )}
      <RevealWrapper className="mb-24 lg:mb-10">
        <Swiper
          ref={servicesCarousel}
          slidesPerView="auto"
          freeMode={true}
          grabCursor
        >
          {services.map((item, index) => {
            return (
              <SwiperSlide
                className={index === 0 ? "ml-ogs lg:pl-col" : ""}
                key={item.title + index}
              >
                <ServicesCard data={item} className="h-full" />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="mt-8 px-ogs lg:grid lg:grid-cols-12 lg:gap-gs">
          <ServicesCarouselControls next={next} previous={previous} />
        </div>
      </RevealWrapper>
    </div>
  )
}

ServicesCarousel.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    services: PropTypes.array.isRequired,
  }),
}

export default ServicesCarousel
