import React from 'react'

const IconStar = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 20 20">
      <path d="M4.1 19.5c-.1 0-.2 0-.3-.1-.2-.1-.2-.3-.2-.5l1.1-6.2L.2 8.2C0 8.1 0 7.9 0 7.7c.1-.2.2-.3.4-.3l6.3-.9L9.5.8c.2-.3.7-.3.9 0l2.8 5.7 6.3.9c.2 0 .3.2.4.3.1.2 0 .4-.1.5l-4.6 4.4 1.1 6.2c0 .2 0 .4-.2.5-.2.1-.4.1-.5 0L10 16.5l-5.6 2.9c-.1.1-.2.1-.3.1zm5.9-4.1c.1 0 .2 0 .2.1l5 2.6-.9-5.5c0-.2 0-.3.1-.4l4-3.9-5.6-.8c-.2 0-.3-.1-.4-.3l-2.5-5-2.5 5c0 .1-.1.2-.3.2l-5.5.8 4 3.9c.1.1.2.3.1.4L4.8 18l5-2.6h.2z" />
    </svg>
  )
}

export default IconStar
