import React from "react"

const CardView = ({ data, imageData }) => {
  const { heading, items } = data

  return (
    <div
      className={`mx-ogs grid rounded-2xl bg-white py-15 md:pt-20 md:pb-24 lg:grid-cols-12 lg:gap-x-gs ${
        imageData.images ? "-mb-40 lg:-mb-60" : ""
      }`}
    >
      <div className="text-center lg:col-span-12 lg:mx-ogs xl:col-span-10 xl:col-start-2 xl:mx-0">
        {heading && (
          <h3 className="mb-10 px-12 text-3xl font-semibold md:px-0 lg:mb-18 lg:text-4xl">
            {heading}
          </h3>
        )}
        {items.length > 0 && (
          <div className="mx-auto grid max-w-xs gap-y-10 px-ogs md:border-blue-200 md:px-0 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-y-0 lg:border-r-3 lg:px-0">
            {items.map((item, index) => {
              const realIndex = index + 1
              // finds number of items in last row
              const remainder = items.length % 3 !== 0 ? items.length % 3 : 3

              let featureYBorderXl = ""
              // prevents border being added to last row of items
              if (realIndex <= items.length - remainder) {
                featureYBorderXl = "lg:border-b-3"
              } else {
                featureYBorderXl = "lg:border-b-0"
              }

              let featureYSpacingXl = ""
              if (items.length !== 3) {
                if (realIndex <= items.length - remainder) {
                  if (realIndex > 3) {
                    featureYSpacingXl = "lg:py-10"
                  } else {
                    featureYSpacingXl = "lg:pb-10 lg:pt-0"
                  }
                } else {
                  featureYSpacingXl = "lg:pt-10 lg:pb-0"
                }
              } else {
                featureYSpacingXl = "lg:py-0"
              }

              const featureWrapperClass = `${
                index === 0 ? "" : "pt-10 lg:pt-0 border-t-3 lg:border-t-0"
              } lg:border-l-3 border-blue-200`

              const featureClass = `${featureYBorderXl} ${featureYSpacingXl} lg:h-full lg:flex lg:flex-col lg:items-center lg:justify-center lg:border-blue-200 lg:mx-ogs xl:mx-12`

              return (
                <div key={item.heading + index} className={featureWrapperClass}>
                  <div className={featureClass}>
                    {item.heading && (
                      <h4 className="mb-4 text-xl font-semibold text-blue-500">
                        {item.heading}
                      </h4>
                    )}
                    {item.text && (
                      <p className="whitespace-pre-wrap text-md leading-6">
                        {item.text}
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default CardView
