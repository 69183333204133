import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import FeaturesItem from "./FeaturesItem"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const FeaturesItems = ({ items, style }) => {
  const featuresCarousel = useRef(null)

  let featureWidthClass = ""
  // let circleBgClass = ""

  const renderItems = items => {
    return (
      <div className="flex flex-col items-center md:items-stretch md:flex-row md:flex-wrap md:justify-center -mb-15 -ml-gs">
        {items.map((item, index) => {
          // if (items.length > 3) {
          //   if ((index + 1) % 2 === 1) {
          //     circleBgClass = "bg-pink-500"
          //   } else {
          //     circleBgClass = "bg-yellow-500"
          //   }
          // } else {
          //   switch ((index + 1) % 3) {
          //     case 1:
          //       circleBgClass = "bg-pink-500"
          //       break
          //     case 2:
          //       circleBgClass = "bg-yellow-500"
          //       break
          //     case 0:
          //       circleBgClass = "bg-blue-500"
          //       break

          //     default:
          //       circleBgClass = "bg-pink-500"
          //       break
          //   }
          // }

          if (style === "default") {
            if (items.length > 3) {
              featureWidthClass = "w-72"
            } else {
              featureWidthClass = "w-72 xs:w-80"
            }
          } else {
            featureWidthClass = "w-48 2xl:w-52"
          }

          const circleClass = `w-20 h-20 -mb-10 relative z-10 rounded-full flex items-center justify-center text-5xl flex-shrink-0 text-white bg-yellow-500 `

          // Background class in circleClass was previously ${circleBgClass}, have left in for possible future use

          const { heading, text } = item

          if (style !== "default")
            return (
              <SwiperSlide
                key={item.text + index}
                className={`mr-gs md:mr-0 md:pb-15 pl-gs ${
                  index === 0 ? "ml-ogs md:ml-0" : ""
                }`}
              >
                <FeaturesItem
                  heading={heading}
                  text={text}
                  index={index + 1}
                  circleClass={circleClass}
                  featureWidthClass={featureWidthClass}
                  style={style}
                />
              </SwiperSlide>
            )

          return (
            <RevealWrapper
              delay={index * 250}
              key={item.text + index}
              className="pb-15 pl-gs"
            >
              <FeaturesItem
                heading={heading}
                text={text}
                index={index + 1}
                circleClass={circleClass}
                featureWidthClass={featureWidthClass}
                style={style}
              />
            </RevealWrapper>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {style !== "default" && (
        <Swiper
          slidesPerView="auto"
          freeMode={true}
          grabCursor
          ref={featuresCarousel}
          className="md:hidden"
        >
          {renderItems(items)}
        </Swiper>
      )}
      <div
        className={`px-ogs w-full ${
          style === "default" ? "" : "hidden md:block"
        }`}
      >
        {renderItems(items)}
      </div>
    </>
  )
}

FeaturesItems.propTypes = {
  item: PropTypes.array,
  style: PropTypes.string,
}

export default FeaturesItems
