import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const TextAndImagesImageSingle = ({ images, imageOffset, imageAlignment }) => {
  const imageData = {
    image: getImage(images[0].localFile),
    alt: images[0].alt || "",
  }

  let imageBorderClasses = ""

  if (imageOffset) {
    imageBorderClasses =
      imageAlignment === "left" ? "rounded-r-4xl" : "rounded-l-4xl"
  } else {
    imageBorderClasses = "rounded-4xl"
  }
  return (
    <GatsbyImage
      className={`${imageBorderClasses} overflow-hidden transform-gpu w-full`}
      image={imageData.image}
      alt={imageData.alt}
    />
  )
}

TextAndImagesImageSingle.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      localFile: PropTypes.shape(),
    })
  ),
  imageOffset: PropTypes.bool,
  imageAlignment: PropTypes.string,
}

export default TextAndImagesImageSingle
