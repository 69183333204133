import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BLUE, PINK, YELLOW } from "../../../constants"

import Logomark from "../../SVG/Logomark"
import RevealWrapper from "../../RevealWrapper/RevealWrapper"

const InformationGrid = ({ data }) => {
  const {
    backgroundColour,
    image,
    heading,
    lead,
    text,
    informationGridColumns,
  } = data

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].title,
    }
  }

  const getBackgroundClass = () => {
    switch (backgroundColour) {
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return "bg-blue-500"
    }
  }

  return (
    <div
      className={`pt-6 lg:pt-12  overflow-hidden relative ${getBackgroundClass()} ${
        imageData.image ? "lg:pb-1/3" : "pb-6 lg:pb-12"
      }`}
    >
      <RevealWrapper className="z-10 relative">
        <div className="mx-ogs bg-white rounded-2xl px-8 lg:px-0 lg:grid lg:grid-cols-12 lg:gap-x-gs pt-14 lg:py-20 text-center lg:text-left">
          {heading && (
            <div className="lg:col-span-10 lg:col-start-2">
              <div className="lg:flex lg:items-center space-y-2.5 lg:space-y-0 lg:space-x-5 pb-10 lg:pb-15">
                <div className="w-6 h-3.5 mx-auto lg:mx-0 text-blue-500 mb-5 lg:mb-0">
                  <Logomark />
                </div>
                <h3 className="font-semibold whitespace-pre-wrap">{heading}</h3>
              </div>
            </div>
          )}
          <div className="lg:col-span-10 lg:col-start-2 lg:grid lg:grid-cols-2 lg:gap-x-24">
            {lead && (
              <div className="text-blue-500 font-semibold text-xl lg:text-2xl mb-10 lg:mb-0">
                {lead}
              </div>
            )}
            {text && (
              <div
                className="pb-15 rte"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}

            {informationGridColumns.length > 0 && (
              <div className="col-span-2 border-blue-200 lg:border-b-2 lg:grid lg:grid-cols-2">
                {informationGridColumns.map((item, index) => {
                  const { heading, text } = item
                  const wrapperClasses =
                    index % 2 === 1
                      ? "lg:border-blue-200 lg:border-l-2 lg:pl-15"
                      : "lg:pr-15"
                  return (
                    <div
                      className={`py-12 border-blue-200 border-t-3 lg:border-t-2`}
                      key={`${index} ${heading}`}
                    >
                      <div className={`space-y-6 ${wrapperClasses}`}>
                        {heading && (
                          <h4 className="text-blue-500 font-semibold text-xl">
                            {heading}
                          </h4>
                        )}
                        {text && <div>{text}</div>}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </RevealWrapper>
      {imageData.image && (
        <div className="-mt-28 lg:mt-0 lg:absolute lg:inset-x-0 lg:bottom-0">
          <GatsbyImage
            className="h-0 pb-9:10 md:pb-4:3 w-full"
            image={imageData.image}
            alt={imageData.alt}
          />
        </div>
      )}
    </div>
  )
}

InformationGrid.propTypes = {
  data: PropTypes.shape({
    backgroundColour: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.shape()),
    heading: PropTypes.string,
    lead: PropTypes.string,
    text: PropTypes.string,
    informationGridColumns: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
}

export default InformationGrid
